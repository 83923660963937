main {
	.about {
		.tagline {
			color: #666;
		}
		img {
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
			border-radius: 50%;
		}
	}
	.post {
		blockquote {
			padding: 0 1em;
			border-left: .25em solid #e3e3e3;
			margin-left: 24px;
		}

		.video-container {
			position: relative;
			padding-bottom: 56.25%;
			padding-top: 35px;
			height: 0;
			overflow: hidden;
		}
		.video-container iframe {
			position: absolute;
			top:0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.image-container {
			max-width: 100%;
		}
	}
}

.icon-stackoverflow:before { content: '\f16c'; } /* '' */

pre .hljs {
	margin: 0px !important;
	line-height: 1.4;
	font-size: 80%
}
